import { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const QUERY = graphql`
  query DynamicInitialInteractionUrls {
    allContentfulArticle {
      nodes {
        slug
      }
    }
    allContentfulBlogCategoryFeed {
      nodes {
        slug
      }
    }
  }
`

type DynamicInitialInteractionUrlsQueryResponse = {
  allContentfulArticle: { nodes: { slug: string }[] }
  allContentfulBlogCategoryFeed: { nodes: { slug: string }[] }
}

export const useDynamicInitialInteractionUrls = (): Record<
  'blog',
  string[]
> => {
  const { allContentfulArticle, allContentfulBlogCategoryFeed } =
    useStaticQuery<DynamicInitialInteractionUrlsQueryResponse>(QUERY)

  return useMemo(() => {
    return {
      blog: [
        ...allContentfulArticle.nodes.map((n) => `/${n.slug}/`),
        ...allContentfulBlogCategoryFeed.nodes.map((n) => `/${n.slug}/`),
      ],
    }
  }, [allContentfulArticle.nodes, allContentfulBlogCategoryFeed.nodes])
}
