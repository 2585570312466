/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================
import { PaymentSource } from '_generated/gql/graphql'

import { Period } from '_generated/gql/graphql'

export { Period }

export enum AddressState {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
}

export enum AuthMethod {
  OTP = 'OTP',
  PASSWORD = 'PASSWORD',
}

export enum DeviceNotificationPermission {
  DENIED = 'DENIED',
  EPHEMERAL = 'EPHEMERAL',
  GRANTED = 'GRANTED',
  NOT_DETERMINED = 'NOT_DETERMINED',
  PROVISIONAL = 'PROVISIONAL',
}

export enum DeviceType {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB',
}

export enum FulfillmentShipmentStatus {
  ATTEMPTED_DELIVERY = 'ATTEMPTED_DELIVERY',
  CONFIRMED = 'CONFIRMED',
  DELIVERED = 'DELIVERED',
  FAILURE = 'FAILURE',
  IN_TRANSIT = 'IN_TRANSIT',
  LABEL_PRINTED = 'LABEL_PRINTED',
  LABEL_PURCHASED = 'LABEL_PURCHASED',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
}

export enum FulfillmentTrackingCompany {
  AMAZON_LOGISTICS_UK = 'AMAZON_LOGISTICS_UK',
  AMAZON_LOGISTICS_US = 'AMAZON_LOGISTICS_US',
  ANJUN_LOGISTICS = 'ANJUN_LOGISTICS',
  APC = 'APC',
  AUSTRALIA_POST = 'AUSTRALIA_POST',
  A_4PX = 'A_4PX',
  BLUEDART = 'BLUEDART',
  CANADA_POST = 'CANADA_POST',
  CANPAR = 'CANPAR',
  CHINA_POST = 'CHINA_POST',
  CHUKOU1 = 'CHUKOU1',
  CORREIOS = 'CORREIOS',
  COURIERS_PLEASE = 'COURIERS_PLEASE',
  DELHIVERY = 'DELHIVERY',
  DHL_ECOMMERCE = 'DHL_ECOMMERCE',
  DHL_ECOMMERCE_ASIA = 'DHL_ECOMMERCE_ASIA',
  DHL_EXPRESS = 'DHL_EXPRESS',
  DPD = 'DPD',
  DPD_LOCAL = 'DPD_LOCAL',
  DPD_UK = 'DPD_UK',
  EAGLE = 'EAGLE',
  FEDEX = 'FEDEX',
  FSC = 'FSC',
  GLOBEGISTICS = 'GLOBEGISTICS',
  GLS = 'GLS',
  GLS_US_ = 'GLS_US_',
  JAPAN_POST = 'JAPAN_POST',
  LA_POSTE = 'LA_POSTE',
  NEWGISTICS = 'NEWGISTICS',
  NEW_ZEALAND_POST = 'NEW_ZEALAND_POST',
  OTHER = 'OTHER',
  POSTNL = 'POSTNL',
  POSTNORD = 'POSTNORD',
  PUROLATOR = 'PUROLATOR',
  ROYAL_MAIL = 'ROYAL_MAIL',
  SAGAWA = 'SAGAWA',
  SENDLE = 'SENDLE',
  SFC_FULFILLLMENT = 'SFC_FULFILLLMENT',
  SF_EXPRESS = 'SF_EXPRESS',
  SINGAPORE_POST = 'SINGAPORE_POST',
  STARTRACK = 'STARTRACK',
  TNT = 'TNT',
  TOLL_IPEC = 'TOLL_IPEC',
  UPS = 'UPS',
  USPS = 'USPS',
  WHISTL = 'WHISTL',
  YAMATO = 'YAMATO',
  YUNEXPRESS = 'YUNEXPRESS',
}

export enum OrderCancelReason {
  CUSTOMER = 'CUSTOMER',
  DECLINED = 'DECLINED',
  FRAUD = 'FRAUD',
  INVENTORY = 'INVENTORY',
  OTHER = 'OTHER',
  PRESCRIPTION_REJECTED = 'PRESCRIPTION_REJECTED',
}

export enum PetAppetite {
  LESS_THAN_NORMAL = 'LESS_THAN_NORMAL',
  MORE_THAN_NORMAL = 'MORE_THAN_NORMAL',
  NONE_MORE_THAN_24_HOURS = 'NONE_MORE_THAN_24_HOURS',
  NORMAL = 'NORMAL',
}

export enum PetBehavior {
  ANXIOUS = 'ANXIOUS',
  LETHARGIC = 'LETHARGIC',
  NORMAL = 'NORMAL',
}

export enum PetWaterConsumption {
  LESS_THAN_NORMAL = 'LESS_THAN_NORMAL',
  MORE_THAN_NORMAL = 'MORE_THAN_NORMAL',
  NONE_MORE_THAN_24_HOURS = 'NONE_MORE_THAN_24_HOURS',
  NORMAL = 'NORMAL',
}

export enum PlanStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  DUNNING = 'DUNNING',
  EXPIRED = 'EXPIRED',
  REPLACED = 'REPLACED',
  TRIAL = 'TRIAL',
}

export enum PrescriptionStatus {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
}

export enum PriceTypeEnum {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
}

export enum ProductVendorType {
  FIRST_PARTY = 'FIRST_PARTY',
  THIRD_PARTY = 'THIRD_PARTY',
}

export enum QuantityType {
  CHEW = 'CHEW',
  GRAMS = 'GRAMS',
  ITEM = 'ITEM',
  MILLIGRAMS = 'MILLIGRAMS',
  PILL = 'PILL',
  TABLET = 'TABLET',
}

export enum ShippingAddressState {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
}

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  INCOMPLETE = 'INCOMPLETE',
  PAST_DUE = 'PAST_DUE',
  TRIAL = 'TRIAL',
  UNPAID = 'UNPAID',
}

export interface ActivateAddOnInput {
  addOn: PlanAddOnInput
  attribution?: AttributionInput | null
  paymentMethod?: string | null
  clientMutationId?: string | null
}

export interface AttributionInput {
  channel: string
  clickId?: string | null
  meta?: CoreJSONString | null
}

export interface BirthdayInput {
  birthday: string
  accuracyLevel: string
}

export interface CreateUserPaymentMethodInput {
  nickname?: string | null
  source: PaymentSource
  token?: string | null
  processorId?: string | null
}

export interface DeviceInput {
  deviceIdentifier?: string | null
  deviceType: DeviceType
  notificationPermission?: DeviceNotificationPermission | null
  deviceName?: string | null
  osVersion?: string | null
  applicationVersion?: string | null
  timezone?: string | null
  locale?: string | null
  isAdTrackingEnabled?: boolean | null
  advertisingId?: string | null
  appsFlyerId?: string | null
  trackingStatus?: string | null
  externalIds?: CoreJSONString | null
  pushToken?: string | null
}

export interface GuestSessionInput {
  identifier: string
  token: string
}

export interface LocationInput {
  address1?: string | null
  address2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
}

export interface LoginV2Input {
  username: string
  password: string
  guestSession?: GuestSessionInput | null
  clientMutationId?: string | null
}

export interface MicrochipInput {
  id: string
  brand?: string | null
}

export interface PlanAddOnInput {
  slug: CoreSlug
  petId?: number | null
  interval?: number | null
  period?: Period | null
}

export interface PlanQuoteInput {
  plan: string
  interval?: number | null
  period?: Period | null
  startInTrial?: boolean | null
  configuration?: string | null
}

export interface QuoteInput {
  plan?: string | null
  membership?: PlanQuoteInput | null
  promoCode?: string | null
  giftCode?: string | null
  shippingAddress?: string | null
  addOns?: (PlanAddOnInput | null)[] | null
}

export interface RabiesInput {
  tagNumber: string
  brand?: string | null
}

export interface RegisterDeviceInput {
  device: DeviceInput
  clientMutationId?: string | null
}

export interface SelectPlanInput {
  plan: CoreSlug
  interval?: number | null
  period?: Period | null
  promoCode?: string | null
  newPaymentMethod?: CreateUserPaymentMethodInput | null
  startInTrial?: boolean | null
  addOns?: (PlanAddOnInput | null)[] | null
  configuration?: string | null
}

export interface SendOTPInput {
  username: string
  clientMutationId?: string | null
}

export interface SetUserPlanInput {
  giftCode?: string | null
  attribution?: AttributionInput | null
  plan?: SelectPlanInput | null
  updateUser?: UpdateUserInput | null
  newPaymentMethod?: CreateUserPaymentMethodInput | null
  clientMutationId?: string | null
}

export interface SignupV2Input {
  username: string
  password: string
  attribution?: AttributionInput | null
  clientMutationId?: string | null
}

export interface UpdateCartLineItemInput {
  productVariantId: string
  quantity: number
  interval?: number | null
  period?: Period | null
}

export interface UpdateUserInput {
  email?: string | null
  firstName?: string | null
  lastName?: string | null
  password?: string | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================
